import React, { useState } from "react"
import Layout from "../components/layout"
import {Jumbotron, Card, Form, Button, Alert} from 'react-bootstrap'
import '../styles/contact.css';

export const seo = {
  title: 'Contact Us | Crampete',
  description: 'Crampete is present in 10 locations in Singapore, India &amp; Malaysia. Contact us to up-skill yourself or your students, or start a learning centre in your city.',
  keywords: 'Crampete, Contact',
  image: '',
  url: 'https://www.crampete.com/contact',
  author: 'Crampete'
}

const Contact = () => {

  const [ formData, setFormData ] = useState(
    {
      fields: {},
      errors: {},
      success: '',
      submitState : false
    }
  )

  const handleValidation = () =>{
    let fields = formData.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Please enter your name";
    }

    //Mobile
    if(!fields["mobile"]){
    formIsValid = false;
    errors["mobile"] = "Please enter your mobile number";
    }
    else if(fields["mobile"].length !== 10)
    {
      formIsValid = false;
      errors["mobile"] = "Please enter valid mobile number";
    }

    //Email
    if(!fields["email"]){
    formIsValid = false;
    errors["email"] = "Please enter your email";
    }

    if(typeof fields["email"] !== "undefined"){
    let lastAtPos = fields["email"].lastIndexOf('@');
    let lastDotPos = fields["email"].lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
      formIsValid = false;
      errors["email"] = "Please enter valid email";
    }
    }
    
    

   setFormData((prevState) => {
    return { ...prevState, errors: errors };
   });
   return formIsValid;
   } 

   const handleChange = (field, e) => {  
    let fields = formData.fields;
    fields[field] = e.target.value;        
    setFormData((prevState) => {
      return { ...prevState, fields: fields };
     });
   }

   const handleSubmit = async event => {
    event.preventDefault()
    handleValidation()
    if(handleValidation()){

      setFormData((prevState) => {
        return { ...prevState, submitState: true };
       });

      document.getElementById("contact-submit").innerHTML = 'Please wait...';
      const opts = {
        "Name": formData.fields["name"],
        "EmailID": formData.fields["email"],
        "MobileNumber": formData.fields["mobile"],
        "EmailTemplate": "CourseTemplate",
        "Source": "Contact",
      }

      fetch('https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/leads/create-lead', {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(opts)
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            // console.log(data);
            if(data.success) {

              // setFormData({success: true});
              setFormData((prevState) => {
                return { ...prevState, success: true };
               });

              document.getElementById("contact-submit").innerHTML = 'Submit';
              
                
            } else {
              setFormData((prevState) => {
                return { ...prevState, success: false };
               });
              document.getElementById("contact-submit").innerHTML = 'Submit';
            }

            

            setTimeout(
              () => setFormData((prevState) => {
                return { ...prevState, submitState: false, fields: {},success: false  };
               }),
              5000
            )
            document.getElementById("contact-form").reset()
            
          });

    } 
   }
  
  // console.log(formData)
  
  const rspSuccess = (
    <Alert
      className={`${formData.success === true ? "d-block" : "d-none"}`}
      variant="success"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      Thank you, your enquiry has been submitted !
    </Alert>
  )
  const rspFail = (
    <Alert
      className={`${formData.success === false ? "d-block" : "d-none"}`}
      variant="danger"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      Something went wrong please try again later!
    </Alert>
  )
  const submitMsg = formData.success ? rspSuccess : rspFail

  return (

  <Layout title={seo.title} description={seo.description} image={seo.image} keywords={seo.keywords} url={seo.url} author={seo.author} >
    <Jumbotron className="bg-lightblue">
      <div class="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Contact</h2>
          <p className="mt-4">
           our learning centres
          </p>
        </div>
      </div>
    </Jumbotron>
    <div class="contactPageSection">
    <div class="container">
        <div className="row">
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Chennai - T.Nagar</Card.Title>
                  <Card.Text>
                  <p>#132, Habibullah Rd, Satyamurthy Nagar, T. Nagar, Chennai, Tamil Nadu 600017</p>
									<p>chennai@crampete.com</p>
									<p>+91 93840 58984</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Chennai - Mount Road</Card.Title>
                  <Card.Text>
                  <p>Nizara Bonanza, (Adjacent to Higginbotham Book Store / Opp to LIC), New# 118, Old# 813, Annasalai, Chennai, Tamil Nadu 600002</p>
									<p>chennai@crampete.com</p>
									<p>+91 93840 58984</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Singapore</Card.Title>
                  <Card.Text>
                  <p>1 North Bridge Road, High Street Centre #19-04/05, Singapore</p>
									<p>singapore@crampete.com</p>
									<p>+60 12-336 9057</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Malaysia</Card.Title>
                  <Card.Text>
                  <p>Block B-04-07A, Pusat Perdagangan Puchong Prima, Jalan Prima 5/5, 47100 Puchong, Selangor</p>
									<p>malaysia@crampete.com</p>
									<p>+60 12-336 9057</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Hanumanth Nagar</Card.Title>
                  <Card.Text>
                  <p>NICT Computer Education # 13/30, 50 feet Main Road, Hanumanthnagar Bangalore 560050. Karnataka, India</p>
					      	<p>email : shivakumar@nictinfo.com</p>
						      <p>phone : 7676312345</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Vijayanagar</Card.Title>
                  <Card.Text>
                  <p>NICT Computer Education # 39/39, 91st "E" cross, REMCO layout, vijayanagar 2 nd stage Bangalore - 40. Karnataka, India oppo BMTC Bus stop, metro pillar no : 331</p>
						      <p>email : kanakaraj@nicteducation.com</p>
						      <p>phone : 7338680777, 7338680777 </p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Rammurthynagar</Card.Title>
                  <Card.Text>
                  <p>NICT Computer Education # 183, 1st Main Road 2nd floor, Rammurthynagar Bangalore 560016. Karnataka, India oppo old police station </p>
						      <p>email : bangalore@crampete.com</p>
						      <p>phone : 996194324, 08041679695 </p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bangalore - Jayanagar</Card.Title>
                  <Card.Text>
                  <p>NICT Computer Education # 791, 9th Main Road 4th Block west, Jayanagar Bangalore 560011. Karnataka, India </p>
						     <p>email : bangalore@crampete.com</p>
						     <p>phone : 996194324, 08041679695 </p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Bhubaneswar</Card.Title>
                  <Card.Text>
                  <p>Regional Collage of Managment , Chakadola Dham, Chandrasekharpur, Bhubanswar- 751023, Odisha</p>
									<p>bhubaneswar@crampete.com</p>
									<p>+91 7437000204</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
            <div class="col-md-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Raipur</Card.Title>
                  <Card.Text>
                  <p>3rd Floor, ACE Globle, GE Road, Raipur 492006</p>
									<p>raipur@crampete.com</p>
                  </Card.Text>
                </Card.Body>
              </Card> 
            </div>   
        </div>

        <div className="sec-divider"></div> 
        <div className="contactFormOuter">
           <h3>Send a Message</h3>
           <Form id="contact-form" method="post" onSubmit= {handleSubmit}>
           {formData.submitState ? submitMsg : ""}
            <div className="row">
              <div className="col-md-4">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" onChange={handleChange.bind(this, "name")}/>
                  <Form.Text className="text-danger">{formData.errors["name"]}</Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" onChange={handleChange.bind(this, "email")}/>
                  <Form.Text className="text-danger">{formData.errors["email"]}</Form.Text>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="mobile">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="number" name="mobile" onChange={handleChange.bind(this, "mobile")}/>
                  <Form.Text className="text-danger">{formData.errors["mobile"]}</Form.Text>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
              <Form.Group controlId="phone">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows="5" name="message" onChange={handleChange.bind(this, "message")}/>
                  <Form.Text className="text-danger"></Form.Text>
                </Form.Group>
              </div>
            </div>
            <Button variant="primary" type="submit" id="contact-submit">
                Submit
            </Button>
           </Form>
        </div>
    </div>
    </div>
  </Layout>
)
  }

export default Contact  
